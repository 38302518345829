<template>
  <div class="text-light mt-5">
    <p class="fs-4 text-center fw-bold">在线客服</p>
    <div class="d-grid gap-2 my-3 btn-bar">
      <a target="_blink"
      href="https://chatlink.mstatik.com/widget/standalone.html?eid=53ead8ff62d49796444ba322ba7ad309" class="btn btn-primary"
      type="button">在线客服链接</a>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div
          class="shadow bg-secondary p-3 mb-3 w-100 d-flex justify-content-between align-items-center rounded"
        >
          <div class="">
            <p>QQ 客服: 安妮</p>
            <p class="mb-0">QQ 帐号: <b id="qqChatAccount">190193666</b></p>
          </div>
          <div class="d-flex">
            <span class="mb-0 me-2" v-if="copied === 'qq'"
              ><small>已複製！</small></span
            >
            <button
              type="button"
              class="btn btn-sm btn-outline-main"
              id="copyQQAccount"
              data-clipboard-target="#qqChatAccount"
              @click="copyText('qq')"
            >
              複製帐号
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div
          class="shadow bg-secondary p-3 mb-3 w-100 d-flex justify-content-between align-items-center rounded"
        >
          <div class="">
            <p>微信客服: 木木</p>
            <p class="mb-0">微信帐号: <b id="weChatAccount">hg_MuMu</b></p>
          </div>
          <div class="d-flex">
            <span class="mb-0 me-2" v-if="copied === 'weChat'"
              ><small>已複製！</small></span
            >
            <button
              type="button"
              class="btn btn-sm btn-outline-main"
              id="copyWeChatAccount"
              data-clipboard-target="#weChatAccount"
              @click="copyText('weChat')"
            >
              複製帐号
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div
          class="shadow bg-secondary p-3 mb-3 w-100 d-flex justify-content-between align-items-center rounded"
        >
          <div class="">
            <p>钉钉客服: 倩倩</p>
            <p class="mb-0">钉钉帐号: <b id="dingAccount">hgqianqian</b></p>
          </div>
          <div class="d-flex">
            <span class="mb-0 me-2" v-if="copied === 'ding'"
              ><small>已複製！</small></span
            >
            <button
              type="button"
              class="btn btn-sm btn-outline-main"
              id="copyDingAccount"
              data-clipboard-target="#dingAccount"
              @click="copyText('ding')"
            >
              複製帐号
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div
          class="shadow bg-secondary p-3 mb-3 w-100 d-flex justify-content-between align-items-center rounded"
        >
          <div class="">
            <p>Mchat 客服: 泡芙</p>
            <p class="mb-0">Mchat 帐号: <b id="mChatAccount">hg_22a</b></p>
          </div>
          <div class="d-flex">
            <span class="mb-0 me-2" v-if="copied === 'mChat'"
              ><small>已複製！</small></span
            >
            <button
              type="button"
              class="btn btn-sm btn-outline-main"
              id="copyMChatAccount"
              data-clipboard-target="#mChatAccount"
              @click="copyText('mChat')"
            >
              複製帐号
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-light mt-5">
    <p class="fs-4 text-center fw-bold">下載 MChat</p>
    <div class="d-flex flex-md-row flex-column gap-5 justify-content-center">
      <div class="d-flex flex-column align-items-center">
        <p class="fs-5 text-center fw-bold">安卓手机</p>
        <a class="btn btn-outline-main fs-3" @click="checkDownload"
          ><i class="bi bi-android2"></i>下载安装档</a
        >
      </div>
      <div class="d-flex flex-column align-items-center">
        <p class="fs-5 fw-bold">苹果手机</p>
        <a
          href="https://itunes.apple.com/cn/app/mchat-messenger/id1340850682?mt=8"
          target="_blink"
          type="button"
          class="btn btn-outline-main fs-3"
        >
          <i class="bi bi-apple"></i>
          下载链接
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "/libs/clipboard.min.js";
export default {
  data() {
    return {
      copied: "",
    };
  },
  methods: {
    copyText(app) {
      this.copied = app;
    },
  },
  mounted() {
    new Clipboard("#copyMChatAccount");
    new Clipboard("#copyWeChatAccount");
    new Clipboard("#copyQQAccount");
    new Clipboard("#copyDingAccount");
  },
};
</script>