<template>
  <div class="text-light mb-4 shadow bg-secondary p-3 rounded">
    <p class="text-center fs-4 fw-bold text-warning">
      担心无法进入？<br/>请牢记以下网址！！！
    </p>
    <p class="text-center fs-5">
      如果遇到微信扫码无法进入时<br/>请登录以下网址获取最新登录链接
    </p>
    <ul class="d-flex flex-column fs-5 justify-content-center align-items-center gap-3">
      <li>
        <a class="text-main fs-5" href="hg22b.com">hg22b.com</a>
      </li>
      <li>
        <a class="text-main fs-5" href="hg22c.com">hg22c.com</a>
      </li>
      <li>
        <a class="text-main fs-5" href="hg22d.com">hg22d.com</a>
      </li>
      <li>
        <a class="text-main fs-5" href="hg22e.com">hg22e.com</a>
      </li>
    </ul>
  </div>
</template>