<template>
  <p class="fs-4 text-center fw-bold mt-5 text-light">进入游戏大厅</p>
  <p class="fs-5 text-center fw-bold mt-3 text-light">微信登录二微码</p>
  <div class="text-light">
    <div class="d-flex flex-column gap-3 align-items-center">
      <ul class="">
        <li>1. 请使用手机端微信扫描登录</li>
        <li>2. 手机端长按保存此二微码</li>
        <li>
          3. 如遇
          <b class="text-danger">登入验证错误:无票码</b>，请点画面右下角
          <b class="text-main">使用完整服务</b> 即可完成登录
        </li>
      </ul>
      <img class="qrImg" src="../assets/qrcode.png" alt="" />
    </div>
  </div>
  <p class="fs-5 text-center fw-bold mt-5 text-light">H5 登录</p>
  <div class="d-grid gap-3">
    <a class="btn btn-main" type="button"
    href="https://uzj8i.wht.red/chatbet_v3/game/loginweb.php"
    target="_blink">H5 登录链接</a>
    <button class="btn btn-outline-main"
    @click.prevent="showOptionSelect('loginStep')"
    type="button">H5 登录教程</button>
  </div>
  <div class="mt-3">
    <div
      v-if="showOption === 'loginStep'"
      class="d-flex justify-content-center"
    >
    <Splide :options=options>
      <SplideSlide>

        <img style="width: 100%" src="../assets/H5.jpg" alt="" />
      </SplideSlide>>
    </Splide>>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
export default {
  components: {
    Splide,
    SplideSlide
  },
  data() {
    return {
      showOption: "",
      options: {
        arrows: false
      }
    };
  },
  methods: {
    showOptionSelect(option) {
      this.showOption = option;
    },
    checkDownload() {
      this.$swal
        .fire({
          title: "确定下载 MChat 安着手机 APK 安装档？",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "开始下载",
          denyButtonText: `取消下载`,
          confirmButtonColor: "#0d6efd",
        })
        .then((result) => {
          if (result.isConfirmed) {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const downloadUrl =
                  "https://static.51xulijun.cn/tm/resource/MChatForAndroid.apk";
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "MChatForAndroid.apk";
                link.click();
                resolve();
              }, 1000);
              this.$swal.fire("已開始下載", "", "info");
            });
          } else if (result.isDenied) {
            return;
          }
        });
    },
  },
};
</script>