<template>
  <Splide class="">
    <SplideSlide class="">
      <img
        class="slider-img"
        src="../assets/slider01.png"
        alt=""
      />
    </SplideSlide>
    <SplideSlide class="">
      <img
        class="slider-img"
        src="../assets/slider02.png"
        alt=""
      />
    </SplideSlide>
    <SplideSlide class="">
      <img
        class="slider-img"
        src="../assets/slider03.png"
        alt=""
      />
    </SplideSlide>
  </Splide>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

export default {
  components: {
    Splide,
    SplideSlide
  }
}
</script>