<template>
  <div class="container">
    <!-- <LinkList /> -->
    <Slider />
    <MainOption />
    <OnlineService />
  </div>
</template>

<script>
import MainOption from "@/components/MainOption.vue";
import LinkList from "@/components/LinkList.vue";
import Slider from "@/components/Slider.vue";
import OnlineService from "@/components/OnlineService.vue";



export default {
  components: {
    MainOption,
    LinkList,
    Slider,
    OnlineService
  }
};
</script>
